/*
Отложенные вызовы
• Красный/синий через секунду
• Добавление круга каждую секунду
• ...
• часы (21:06:05 тикают)
• Анимация
• requestAnimationFrame
*/
import TimeIntro from './TimeIntro'
import Video from '@/components/task-templates/Video'

const videoPath = name => `${process.env.BASE_URL}static/js-time/${name}.mp4`

export default [
  {
    label: '!',
    component: TimeIntro
  },
  {
    component: Video,
    name: 'Смена цветов',
    text: '<p>Назвать 01-change-color.html</p><p>При клике на квадрат он должен стать чёрным на 1 секунду, затем вернуть исходный цвет</p>',
    video: videoPath('change-color')
  },
  {
    component: Video,
    name: 'Системное время',
    text: '<p>Назвать 02-clock.html</p><p>Нужно отобразить текущее системное время, используя встроенный класс Date. Обратите внимание, что и часы, и минуты, и секунды должны отображаться с ведущим нулём, если необходимо</p>',
    video: videoPath('clock')
  },
  {
    component: Video,
    name: 'Поймай круг',
    text: '<p>Назвать 03-aim-game.html</p><p>Круг должен появляться в случайном месте экрана (но чтобы даже часть круга не могла быть за границами экрана) через случайное время (от 300 до 1000мс). При клике на круг он должен исчезнуть снова на случайное время и появиться в случайном месте</p>',
    video: videoPath('aim-game')
  },
  {
    component: Video,
    name: 'Имитация загрузки',
    text: '<p>Назвать 04-loading.html</p><p>Каждые 500мс появляется новый элемент. После появления всех элементов нужно снова их скрыть</p>',
    video: videoPath('loading')
  },
  {
    component: Video,
    name: 'Загрузка с ползунком',
    text: '<p>Назвать 05-loading2.html</p><p>Каждые 500мс видимые элементы сдвигаются вправо с переходом в начало</p>',
    video: videoPath('loading2')
  },
  {
    component: Video,
    name: 'Таймеры',
    text: '<p>Назвать 06-timers.html</p><p>В один момент времени только один из таймеров может идти. Если стартовать таймер, поставленный на паузу, то счёт продолжается не с нуля, а с момента остановки. При нажатии на стоп таймер возвращается в исходное состояние</p>',
    video: videoPath('timers')
  },
  {
    component: Video,
    name: 'Пятнашки 3 на 3',
    text: '<p>Назвать 07-pyatnashki3x3.html</p><p>Нужно реализовать такую анимацию. Начальные позиции и движения можно жёстко прописать в коде (то есть не нужно придумывать алгоритм решения пятнашек). В примере начальные позиции: 8 1 3, 2 7 5, 4 6</p>',
    video: videoPath('pyatnashki3x3')
  }
]
// https://learn.javascript.ru/settimeout-setinterval

// дана delay. вывести hello world через delay
// даны from и to. вывести числа от from до to с интервалом 1000мс
// дано n, delay. вывести числа от 1 до n включительно, каждое число с задержкой delay (CodeTest)
// дан массив чисел a. вывести каждое число из массива с задержкой, равной этому числу
// выводить каждую секунду текущее время в формате ЧЧ:ММ:СС
