<template>
  <h2>Отложенные вызовы</h2>
  <p><a href="https://learn.javascript.ru/settimeout-setinterval" class="link" target="_blank">Читать здесь</a></p>
  <p>Для выполнения заданий этой темы создайте в проекте папку "18-time", внутри неё для каждого задания нужно будет создавать отдельный html-файл. Как называть файлы будет написано в задании.</p>
</template>

<script>
export default {
  props: ['task']
}
</script>
